import React from "react";

import { cn } from "~/utilities/cn";

interface IconMenuBarProps extends React.SVGProps<SVGSVGElement> {
  className?: string; // Optional className to pass Tailwind CSS styles
}

const IconMenuBar = React.forwardRef<SVGSVGElement, IconMenuBarProps>(
  ({ className, onClick, ...props }, ref) => {
    return (
      <svg
        ref={ref}
        onClick={onClick}
        className={cn(
          "h-6 w-6 text-color", // Default size and color
          className // Apply any additional className passed via props
        )}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props} // Spread the remaining props (like aria-hidden, etc.)
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
          d="M5 7h14M5 12h14M5 17h14"
        />
      </svg>
    );
  }
);

IconMenuBar.displayName = "IconMenuBar"; // For debugging purposes

export default IconMenuBar;
